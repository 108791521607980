import classNames from 'classnames';
import { ButtonFactory } from '../core/ButtonFactory';
import { IconPosition } from '../core/IconPosition';
import { styleFactory } from '../core/utils';
import { ButtonProps } from '../types';
import styles from './button.module.scss';

export const Button = ({
  className,
  icon,
  style: variant,
  children,
  ...props
}: ButtonProps) => {
  const style = styleFactory(styles, variant).add([styles.button]).get();
  const Component = ButtonFactory(props);

  return (
    <Component className={classNames(className, style)} {...props}>
      <IconPosition icons={icon}>{children}</IconPosition>
    </Component>
  );
};
